// import React from 'react'

// export default function RegisteredVehicle() {
//   return (
//     <div>RegisteredVehicle</div>
//   )
// }

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Link, useNavigate } from "react-router-dom";
// import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
// import auth from "firebase/firestore";
import { useEffect, useState } from "react";
// import { collection, getDoc } from "firebase/firestore/lite";
import { getDatabase, ref, onValue, set, get } from "firebase/database";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";
import { Box, Modal } from "@mui/material";
import ImageModal from "components/ImageModal";

// import projectsTableData from "layouts/tables/data/projectsTableData";
function RegisteredVehicle() {
  const [rows, setRows] = useState([]);

  const [data, setData] = useState([]);

  const [search, setSearchText] = useState(null);

  const navigate = useNavigate();
  const [controller, dispatch] = useMaterialUIController();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [onClickImageData, setOnClickImageData] = useState("");
  const handleImageClick = (url) => {
    setIsModalOpen(true);
    setOnClickImageData(url);
  };

  //   const { columns } = authorsTableData();
  const commonHeaderStyle = { color: "blue", fontWeight: "bold" }; // Adjust the color and other styles as needed

  const columns = [
    { Header: <span style={commonHeaderStyle}>SR</span>, accessor: "SR", align: "center" },
    { Header: <span style={commonHeaderStyle}>Name</span>, accessor: "Name", align: "center" },
    { Header: <span style={commonHeaderStyle}>Number</span>, accessor: "Number", align: "center" },
    { Header: <span style={commonHeaderStyle}>Pin No</span>, accessor: "Pin", align: "center" },
    { Header: <span style={commonHeaderStyle}>Image</span>, accessor: "Image", align: "center" },
  ];

  const naivgate = useNavigate();
  let user = localStorage.getItem("user");

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setRows([]);
    const database = getDatabase();
    const usersRef = ref(database, "/users");
    // Use get method to fetch data once
    get(usersRef)
      .then((snapshot) => {
        const usersData = snapshot.val();
        const dataArray = [];
        let index = 0;

        for (const userId in usersData) {
          if (usersData.hasOwnProperty(userId)) {
            const user = usersData[userId];

            if (user.Registered_Vehicles) {
              for (const vehicleId in user.Registered_Vehicles) {
                if (user.Registered_Vehicles.hasOwnProperty(vehicleId)) {
                  const vehicle = user.Registered_Vehicles[vehicleId];
                  index++;

                  const rowItem = {
                    SR: index,
                    Name: vehicle.vehicleName,
                    Number: vehicle.vehicleNumber,
                    Pin: vehicle.Pin,
                    Image: (
                      <img
                        onClick={() => handleImageClick(vehicle.imgVehicle)}
                        src={vehicle.imgVehicle}
                        alt="Not Available"
                        style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                      />
                    ),
                  };

                  setRows((prev) => [...prev, rowItem]);

                  const vehicleObject = {
                    userId: userId,
                    vehicleName: vehicle.vehicleName,
                    vehicleNumber: vehicle.vehicleNumber,
                    imgVehicle: vehicle.imgVehicle,
                  };

                  dataArray.push(vehicleObject);
                }
              }
            }
          }
        }

        // Set the state with the array of objects
        // setData(dataArray);
      })
      .catch((error) => {
        console.error("Error getting data: ", error);
      });
  };

  const onClose = (event) => {
    setIsModalOpen(false);
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  useEffect(() => {
    if (search == "") {
      getData();
    }

    if (search) {
      const filteredRows = rows.filter((row) => {
        const { Number = "", Name = "", Pin } = row; // Provide default values for destructuring
        const searchLowerCase = search.toLowerCase();
        return (
          Number.toLowerCase().startsWith(searchLowerCase) ||
          Name.toLowerCase().startsWith(searchLowerCase) ||
          Pin.toLowerCase().startsWith(searchLowerCase)
        );
      });

      setRows(filteredRows);
    }
  }, [search]);

  return (
    <DashboardLayout>
      <DashboardNavbar value={search} onChange={handleSearchChange} />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Vehicles
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={true}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <ImageModal onClickImageData={onClickImageData} onClose={onClose} isModalOpen={isModalOpen} />

      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default RegisteredVehicle;
